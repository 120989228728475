import React from "react"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { GatsbyImage, getImage  } from "gatsby-plugin-image"
import FocusStories from "../components/LionsStories/FocusStories"
import { graphql } from "gatsby"

export const query = graphql`
  query($pillarId: String!) {
    stories: allContentfulLionsStory (
      filter: {pillar: {elemMatch: {pillarId: {in: [$pillarId]}}}}
    ){
      edges {
        node {
          name
          excerpt
          contentful_id
          slug
          images {
            gatsbyImageData(width: 600, formats: [AUTO, WEBP])
          }
        }
      }
    }
    pillar: allContentfulLionsPillar(filter: {pillarId: {in: [$pillarId]}}) {
      edges {
        node {
          pillarId
          name
          pillarPhoto {
            gatsbyImageData(width: 2000, formats: [AUTO, WEBP])
            url
            width
            height
          }
        }
      }
    }
  }
`
/*
*/

const PillarStories = ({data}) => {    
  const title = "Lions " + data.pillar.edges[0].node.name + " Stories"
  const image = getImage(data.pillar.edges[0].node.pillarPhoto)
  return (
    <>
      <Seo pageTitle={title}       
          pageImageUrl = {data.pillar.edges[0].node.pillarPhoto.url}
          pageImageWidth = {data.pillar.edges[0].node.pillarPhoto.width}
          pageImageHeight = {data.pillar.edges[0].node.pillarPhoto.height}
          canonicalUri={"/"+ data.pillar.edges[0].node.pillarId}  
      />
      <SimpleBanner title={title}>
      <GatsbyImage
          className="banner__image"
          image={image}
          alt="Banner Image"
        />
      </SimpleBanner>
      <FocusStories data={data}/>
    </>
  )
}

export default PillarStories
