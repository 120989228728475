import React from "react"
import { StoryStyles } from "./StoryStyles"
import FeatureStory from "./FeatureStory"

const FocusStories = (data)  => { 
  const input = data.data.stories.edges

  return (
    <StoryStyles>
      <div className="features__container">
        <div className="features__container--scroll">
          {input.map(({ node }) => {
            return <FeatureStory key={node.contentful_id} feature={node} />
          })}
        </div>
      </div>
    </StoryStyles>
  )
}

export default FocusStories
